import HttpService from './HttpService'

const _http = new HttpService()
export default {
  show: (page, company_id, question_id, appointment_id, video_name, teacher_id, institution_id, date_created, only_suggestions, has_institution) => {
    let _parans = `?size=20&page=${page - 1}&asc`;
    _parans += `&data.company_params_id=${company_id}&data.only_suggestions=${only_suggestions}`;
    if (question_id) {
      _parans += `&data.question_id=${question_id}`;
    }
    if (appointment_id) {
      _parans += `&data.appointment_id=${appointment_id}`;
    }
    if (video_name) {
      _parans += `&data.original_filename=${video_name}`;
    }
    if (teacher_id) {
      _parans += `&data.teacher_id=${teacher_id}`;
    }
    if (institution_id) {
      _parans += `&data.institution_id=${institution_id}`;
    }
    if (date_created) {
      _parans += `&data.date_created=${date_created}`;
    }
    if(has_institution)    {
      _parans += `&data.has_institution=${has_institution}`;
    }
    return _http.get(`/api/upload/manual-question${_parans}`)
  },
  getQuestions: (media_id) => {
    return _http.get(`/api/upload/manual-question/get-by-media/${media_id}`)
  },
  getEditionError: () => {
    return _http.get(`/api/upload/manual-question/show-status-error`)
  },
  update: (payload) => {
    return _http.put('/api/upload/manual-question', payload)
  },
  updateByEditionError: (payload) => {
    return _http.put('/api/upload/manual-question/update-by-edition-error', payload)
  },
  getTabCompany: () => {
    return _http.get(`/api/upload/manual-question/get-tab-company`)
  },
  validateUpload: (id) =>{
    return _http.post(`/api/upload/recordings-upload-controls/validate/${id}`)
  }
}